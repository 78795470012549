import * as React from "react";
import {Link, graphql} from "gatsby";
import "./blog-post.scss";
import Navbar from "../../components/Navbar/navbar";
import Container from "../../components/Container/container";
import {GatsbyImage} from "gatsby-plugin-image";
import {useTranslation} from "react-i18next";
import Footer from "../../components/Footer/footer";


const BlogPostTemplate = ({data: {previous, next, site, markdownRemark: post}, location,}: any) => {

    const {t} = useTranslation();
    const tags = post.frontmatter.tags.join(' ')
    const siteTitle = site.siteMetadata?.title || `Title`
    return (
        <main className="blog-post-template">
            <article className="blog-post-template_article">
                <header>
                    <h1>{post.frontmatter.title}</h1>
                    <div className="blog-post-template_article_info">
                        <span>{post.frontmatter.date}</span>
                        <span>{tags}</span>
                    </div>
                    <GatsbyImage alt={post.frontmatter.title} image={post.frontmatter.featuredImage.childImageSharp.gatsbyImageData}/>
                </header>
                <section dangerouslySetInnerHTML={{__html: post.html}}/>
            </article>
        </main>
    )

}
export default BlogPostTemplate;

export const pageQuery = graphql`
    query BlogPostBySlug(
        $id: String!
        $previousPostId: String
        $nextPostId: String
        $language: String!
    ) {
        site {
            siteMetadata {
                title
            }
        }
        markdownRemark(id: { eq: $id }) {
            id
            excerpt(pruneLength: 160)
            html
            frontmatter {
                title
                date
                description
                tags
                featuredImage {
                    childImageSharp {
                        gatsbyImageData (aspectRatio: 1.78)
                    }
                }
            }
        }
        previous: markdownRemark(id: { eq: $previousPostId }) {
            fields {
                slug
            }
            frontmatter {
                title
            }
        }
        next: markdownRemark(id: { eq: $nextPostId }) {
            fields {
                slug
            }
            frontmatter {
                title
            }
        }
        locales: allLocale(filter: {language: {eq: $language}}) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`